
export default {
    props: {
        multiple: Boolean,

        threshold: {
            type: Number,
            default: 0.9,
        },
    },

    data() {
        return {
            observer: null,
        }
    },

    mounted() {
        if (process.browser) {
            this.observer = new IntersectionObserver(this.callback, {
                root: null,
                threshold: this.threshold,
                rootMargin: '0px',
            })
            this.$nextTick(() => {
                this.observer.observe(this.$el)
            })
        }
    },

    destroyed() {
        this.release()
    },

    methods: {
        callback(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.$emit('intersect', entry)
                    if (!this.multiple) this.release()
                } else {
                    this.$emit('not-intersect', entry)
                }
            })
        },

        release() {
            if (this.observer) {
                this.observer.disconnect()
                this.observer = null
            }
        },
    },

    render(h) {
        return h('div')
    },
}
