import Vue from 'vue'
import { parseISO, format, isValid } from 'date-fns'

Vue.filter('toDate', function (value, formatTemplate = 'MM-dd-yyyy') {
    if (!value) return 'Missing date'
    try {
        if (isValid(value)) return format(value, formatTemplate)
        else if (isValid(parseISO(value))) return format(parseISO(value), formatTemplate)
        else return value
    } catch { return value }
})

Vue.filter('toTime', function (value) {
    if (!value) return 'Missing date'
    try {
        if (isValid(value)) return format(value, 'h:mm a')
        else if (isValid(parseISO(value))) return format(parseISO(value), 'h:mm a')
        else return value
    } catch { return value }
})

Vue.filter('toLocalTime', function (value) {
    if (!value) return 'Missing date'
    try {
        if (isValid(value)) return format(value, 'h:mm a')
        else if (isValid(parseISO(value))) return format(parseISO((value || '').replace(/Z/, '')), 'h:mm a')
        else return value
    } catch { return value }
})

Vue.filter('toDateTime', function (value) {
    if (!value) return 'Missing date'
    try {
        if (isValid(value)) return format(value, 'MM-dd-yyyy h:m a')
        else if (isValid(parseISO(value))) return format(parseISO(value), 'MM-dd-yyyy h:m a')
        else return value
    } catch { return value }
})


Vue.filter('toCurrency', function (value) {
    if (!value) return 'Missing amount'
    return `$${value.toLocaleString()}`
})
