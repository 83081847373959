import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC0Fya1gwxczlcWwH66dLIWmTxp6GmFxc4',
        libraries: 'places',
        language: 'en',
    },
})
