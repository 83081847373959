
import siteMixin from '@/mixins/siteMixin'
import { mapState } from 'vuex'

export default {
    name: 'SetupLayout',

    mixins: [siteMixin],

    computed: {
        ...mapState({
            menu: (state) => state.data.userSubMenu?.items,
            menuTitle: (state) => state.data.userSubMenu?.title,
            menuItemActiveIndex: (state) => state.data.menuItemActiveIndex,
            user: (state) => state.auth.user || {},
            settings: (state) => state.data.userSubMenu?.settings,
        }),

        sidebarVisible() {
            return this.menu && this.$route.query.step !== 0
        },
    },

    methods: {
        globalAction(action) {
            this.$nuxt.$emit(action)
        },
    },
}
