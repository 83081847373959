
import { mapState, mapMutations } from 'vuex'
import posseMenu from '@/mixins/user/posseMenu'
export default {
    mixins: [posseMenu],

    props: {
        isDd: Boolean,
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            isImpersonating: (state) => state.user.isImpersonating,
        }),

        masterClassRoute() {
            return { name: 'tools-videos' }
        },

        masterclassEnabled() {
            return this.$config.masterclassEnabled
        },
    },

    methods: {
        ...mapMutations({
            setProduct: 'products/setItem',
        }),

        logout() {
            this.setProduct()
            this.$auth.logout()
            this.$trackEvent('LOGOUT')
            this.$trackConnect()
        },

        async stopImpersonate() {
            const path = this.$cookies.get('impersonate_path') || '/'
            try {
                await this.$store.dispatch('user/stopImpersonate')
            } catch (q) {}
            if (path === this.$route.path) window.location.reload()
            else this.$router.replace(path)
        },
    },
}
