
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    methods: {
        isHref(link) {
            return link && link.includes('http')
        },
    },
}
