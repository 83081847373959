
export default {
    inheritAttrs: false,

    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: null,
        inputClasses: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            type: 'password',
        }
    },

    methods: {
        toggleType() {
            this.type = this.type === 'password' ? 'text' : 'password'
        },
    },
}
