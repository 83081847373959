export default {
    props: {
        model: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
}
