
import siteMixin from '@/mixins/siteMixin'
import { mapState } from 'vuex'

export default {
    name: 'MasterclassLayout',

    mixins: [siteMixin],

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),
    },
}
