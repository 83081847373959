import { render, staticRenderFns } from "./MasterclassHeader.vue?vue&type=template&id=3973262e&scoped=true&lang=pug"
import script from "./MasterclassHeader.vue?vue&type=script&lang=js"
export * from "./MasterclassHeader.vue?vue&type=script&lang=js"
import style0 from "./MasterclassHeader.vue?vue&type=style&index=0&id=3973262e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3973262e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyScrollObserver: require('/app/components/elements/ScrollObserver.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyLessonListItem: require('/app/components/pages/courses/LessonListItem.vue').default})
