import { mapState } from 'vuex'

export default {
    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'payment.json')
    },

    computed: {
        ...mapState({
            productObject: (state) => state.products.item || {},
            content: (state) => state.jsonloader.data['payment.json'] || null,
        }),

        productName() {
            return this.productObject?.details?.name || ''
        },

        productTitle() {
            return this.content?.product_title.replace('%product_name%', this.productName)
        },
    },
}
