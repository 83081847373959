
import openable from '@/mixins/openable'
export default {
    mixins: [openable],

    props: {
        buttonClasses: {
            type: String,
            default: '',
        },
        closeOnClick: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            watchRoute: false,
        }
    },

    watch: {
        isOpen() {
            this.$emit('toggle', this.isOpen)
        },
    },

    mounted() {
        this.$nuxt.$on('dd-close', (el) => {
            if (this.$refs.dd !== el) this.close()
        })
    },

    methods: {
        toggleDd() {
            if (this.closeOnClick) this.toggle()
        },

        closeOnClickOutside(event) {
            this.toggle()
            this.$nuxt.$emit('dd-close', this.$refs.dd)
        },
    },
}
