export default {
    data() {
        return {
            isScroll: false,
            gap: 64,
        }
    },

    mounted() {
        if (process.browser) {
            window.addEventListener('resize', this.getWidth)
            this.$nextTick(() => this.getWidth())
        }
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.getWidth)
    },

    methods: {
        getWidth() {
            if (process.browser)
                if (this.$refs.navigation) {
                    this.isScroll = this.$refs.navigation.clientWidth + this.gap >= window.innerWidth
                    this.$nextTick(() => {
                        if (this.isScroll) {
                            let el = document.querySelector('.loverly-tab .active')

                            if (!el) el = document.querySelector('.loverly-tab .nuxt-link-active')

                            if (el) {
                                const pos = el.getBoundingClientRect()
                                if (pos.left < 0) {
                                    this.$refs.navigation.scrollLeft = 0
                                    return
                                }
                                if (pos.right > window.innerWidth) this.$refs.navigation.scrollLeft = pos.left
                            }
                        }
                    })
                }
        },
    },
}
