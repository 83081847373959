import Vue from 'vue'

Vue.directive('swipe', {
    bind: function (el, binding, vnode) {
        const touchPoint = {
            x: null,
            y: null,
        }

        const emit = (v) => {
            binding.value(v)
        }

        el.startHandler = (e) => {
            touchPoint.x = e.touches?.[0]?.clientX || e.clientX
            touchPoint.y = e.touches?.[0]?.clientY || e.clientY
        }
        el.moveHandler = (e) => {
            if (!touchPoint.x || !touchPoint.y) return
            let xDiff = touchPoint.x - (e.touches?.[0]?.clientX || e.clientX)
            let yDiff = touchPoint.y - (e.touches?.[0]?.clientY || e.clientY)
            if (Math.abs(xDiff) < 25 && Math.abs(yDiff) < 25) {
                return
            }

            if (Math.abs(xDiff) >= Math.abs(yDiff) && binding.modifiers?.horizontal) {
                if (xDiff > 0) emit({ x: 1, y: 0 })
                else emit({ x: -1, y: 0 })  
                e.preventDefault()
                e.stopPropagation()
            } else if(Math.abs(xDiff) < Math.abs(yDiff) && binding.modifiers?.vertical) {
                if (yDiff > 0) emit({ y: 1, x: 0 })
                else emit({ y: -1, x: 0 })  
                e.preventDefault()
                e.stopPropagation()
            }

            touchPoint.x = null
            touchPoint.y = null
        }

        el.addEventListener('touchstart', el.startHandler)
        el.addEventListener('mousedown', el.startHandler)
        el.addEventListener('touchmove', el.moveHandler)
        el.addEventListener('mousemove', el.moveHandler)
    },
    unbind: function (el) {
        el.removeEventListener('touchstart', el.startHandler)
        el.removeEventListener('mousedown', el.startHandler)
        el.removeEventListener('touchmove', el.moveHandler)
        el.removeEventListener('mousemove', el.moveHandler)
    },
})
