export default {
    data() {
        return {
            services: ['google'],
        }
    },

    methods: {
        getServiceUrl(service) {
            return `${process.env.apiUrl}/api/v1/auth/social/redirect/${service}`
        },
    },
}
