
export default {
    props: {
        icon: {
            type: String,
            default: '',
        },
        to: {
            type: null,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        href: {
            type: String,
            default: '',
        },
        isExpanded: Boolean,
        labelItem: Boolean,
        hiddenOnMobile: Boolean,
    },

    computed: {
        isActive() {
            return false
        },
    },
}
