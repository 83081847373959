
export default {
    props: {
        level: {
            type: null,
            default: 1,
        },
        text: Boolean,
    },

    render(h) {
        return h(!this.text ? `h${this.level}` : 'div', { class: ['heading', this.text ? 'h' + this.level : ''] }, this.$slots.default)
    },
}
