
import siteMixin from '@/mixins/siteMixin'
export default {
    name: 'DefaultLayout',
    mixins: [siteMixin],

    data() {
        return {
            email: '',
        }
    },

    methods: {
        async submitEmail() {
            try {
                await this.$axios
                    .$post(`/api/v1/i`, {
                        email: this.email,
                        event: 'newsletter',
                        props: {},
                    })
                    .then(() => {
                        this.$toast.success('You have successfully subscribed to our newsletter!').goAway(3000)
                        this.email = null
                    })
            } catch (e) {}
        },
    },
}
