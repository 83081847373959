
export default {
    props: {
        white: Boolean,
        size: {
            type: Array,
            default: () => [108, 30],
        },
    },
}
