import { render, staticRenderFns } from "./masterclass.vue?vue&type=template&id=64c55f30&lang=pug"
import script from "./masterclass.vue?vue&type=script&lang=js"
export * from "./masterclass.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyMasterclassHeader: require('/app/components/elements/MasterclassHeader.vue').default,LoverlyDashboardHeader: require('/app/components/pages/dashboard/DashboardHeader.vue').default})
