
import { mapState, mapMutations } from 'vuex'
import posseMenu from '@/mixins/user/posseMenu'

export default {
    mixins: [posseMenu],

    props: {
        isOpen: Boolean,
    },

    data() {
        return {
            isMegaMenuOpen: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },

    methods: {
        ...mapMutations({
            setProduct: 'products/setItem',
        }),

        logout() {
            this.setProduct()
            this.$auth.logout()
            this.$trackConnect()
        },

        close() {
            if (this.$refs.megaMenu) this.$refs.megaMenu.closeMegaMenuItem()
            this.$emit('close')
        },
    },
}
