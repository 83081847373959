
import posseMenu from '@/mixins/user/posseMenu'
export default {
    mixins: [posseMenu],

    computed: {
        dashboardMenu() {
            return [
                { name: 'Dashboard', icon: 'sliders', to: { name: 'dashboard' } },
                { name: 'Checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } },
                { name: 'Guests', icon: 'users', to: { name: 'tools-guest-list' } },
                this.masterclassEnabled ? { name: 'Videos & Guides', icon: 'video', to: { name: 'tools-videos' } } : undefined,
                { name: 'Vendors', icon: 'briefcase', to: { name: 'tools-vendor-manager' } },
                { name: 'My Submissions', icon: 'rings-wedding', to: { name: 'tools-submit-wedding-my-weddings' } },
                { name: 'Boards', icon: 'heart', to: { name: 'users-username-boards', params: { username: this.user?.username } } },
                ...(this.$config.birdieEnabled && this.user && this.user.wedding_details && this.user?.wedding_details?.date
                  ? [
                        {
                            name: 'Registry',
                            icon: 'gift',
                            to: { name: 'tools-cash-registry' },
                        },
                    ]
                  : []),
            ]
        }
    }
}
