
export default {
    props: {
        type: {
            type: String,
            default: 'text',
        },

        // eslint-disable-next-line vue/require-default-prop
        value: null,
        removeClass: Boolean,
        positiveNumber: Boolean,
        placeholder: {
            type: [String, Number],
            default: undefined,
        },
        minLength: {
            type: null,
            default: null,
        },
    },

    watch: {
        value: {
            handler() {
                this.isValid(this.value || '')
            },
            immediate: true,
        },
    },

    methods: {
        onKeyUp(e) {
            if (e.keyCode === 13) this.$emit('enter')
        },

        onKeyDown(e) {
            if (this.positiveNumber) {
                if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
                    return false
                }
            }
        },

        onInput(e) {
            if (this.positiveNumber) this.$emit('input', Math.abs(e.target.value))
            else this.$emit('input', e.target.value)
        },

        isValid(value) {
            if (this.type === 'tel') {
                const regex = /^[+][1] (\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
                this.$emit('valid', value.match(regex))
            }
        },

        onFocus(e) {
            this.$emit('focus')
        },

        onBlur(e) {
            if (this.type === 'tel') {
                let val = this.value || ''
                val = val
                    .replace('+1', '')
                    .trim()
                    .replace(/\D/g, '')
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

                val = !val[2] ? val[1] : '+1 (' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '')
                this.$emit('input', val)
            }
            this.$emit('blur', e)
        },
    },
}
