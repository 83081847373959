import { render, staticRenderFns } from "./ConfirmationPopup.vue?vue&type=template&id=01ed2064&lang=pug"
import script from "./ConfirmationPopup.vue?vue&type=script&lang=js"
export * from "./ConfirmationPopup.vue?vue&type=script&lang=js"
import style0 from "./ConfirmationPopup.vue?vue&type=style&index=0&id=01ed2064&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyLoadingSpinner: require('/app/components/elements/LoadingSpinner.vue').default,LoverlyModal: require('/app/components/elements/Modal.vue').default})
