
import { mapState, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            isMenuOpen: (state) => state.data.isMenuOpen,
        }),

        avatar() {
            return this.user.is_vendor ? this.user.vendor || {} : this.user
        },
    },

    methods: {
        ...mapMutations({
            toggleMenuOpen: 'data/toggleMenuOpen',
        }),
    },
}
