
import hasModel from '@/mixins/hasModel'
import { mapState, mapGetters, mapMutations } from 'vuex'
import impression from '@/mixins/impression'
import hasRegistry from '~/mixins/hasRegistry'

export default {
    mixins: [hasModel, hasRegistry, impression],

    props: {
        disabled: Boolean,
        alt: Boolean,
        done: Boolean,
        active: Boolean,
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        icon() {
            switch (this.model.metas.lesson_type) {
                case 'video':
                    return 'video'

                default:
                    return 'align-left'
            }
        },
    },

    methods: {
        ...mapMutations({
            triggerProductModal: 'products/triggerProductModal',
        }),

        gotoLesson() {
            if (!this.$auth.loggedIn) {
                this.triggerProductModal(this.getRegistry('i_do_crew.product_id'))
                return
            }

            try {
                // console.log('Sending GA EVENT', { eventCategory: 'course', eventAction: 'start', eventLabel: this.model.name, eventValue: 0 })
                this.$trackEvent('CLICK', {
                    name: this.model.name,
                    content_type: this.model.content_type.slug,
                    id: this.model.id,
                })
                this.$gtag.event('course-start', { method: 'Google', event_category: 'course', event_label: this.model.name, value: 0 })
            } catch (e) {}
            const urlArray = this.model.url.split('/')
            this.$router.push({ name: 'users-username-courses-slug-lesson', params: { username: this.user.username, slug: urlArray[2], lesson: urlArray[3] } })
        },
    },
}
