
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    computed: {
        megaMenu() {
            return this.model?.megamenu || {}
        },

        promotion() {
            return this.megaMenu?.promotion
        },

        isHref() {
            return this.promotion?.link.includes('http')
        },

        isImageLinkHref() {
            return this.megaMenu?.imageLink?.includes('http')
        },
    },
}
