import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: '/loading.svg',
    error: '/error.svg',
    attempt: 1,
    observer: true,
    observerOptions: {
        rootMargin: '0px',
        threshold: 0.1,
    },
})
