export const state = () => ({})

export const mutations = {}

export const actions = {
    async delete({ rootState, dispatch }, {id,taxonomy_event_id} ) {
        await this.$axios.$delete(`/api/v1/guest_wedding_events/${id}`)
        const user = JSON.parse(JSON.stringify(rootState.auth.user))
        const weddingDetails = JSON.parse(JSON.stringify(user.wedding_details))
        const index = weddingDetails.events.indexOf(taxonomy_event_id)
        if (index !== -1) {
            weddingDetails.events.splice(index, 1)
            await dispatch('user/update', { ...user, ...{ wedding_details: weddingDetails } }, { root: true })
        }
    },
    async save({ rootState, dispatch }, model){
        const result = await this.$axios[model.id ? '$put' : '$post'](`/api/v1/guest_wedding_events${model.id ? `/${model?.id}`:''}`, model)
        if(!model.id) {
            const user = JSON.parse(JSON.stringify(rootState.auth.user))
            const weddingDetails = JSON.parse(JSON.stringify(user.wedding_details))
            weddingDetails.events.push(result.data.taxonomy_event_id)
            await dispatch('user/update', { ...user, ...{ wedding_details: weddingDetails } }, { root: true })
        }
    },
    savePosse(vuex, {model, userId}){
        return this.$axios[model.id ? '$put' : '$post'](`/api/v1/user-posses/user/${userId}/guest_wedding_events${model.id ? `/${model?.id}`:''}`, model)
    }
}
