import { set } from 'vue'

export const state = () => ({
    isImpersonating: false,
    posse: {},
    events: [],
    posseEvents: [],
})

export const mutations = {
    setImpersonate(state, val) {
        state.isImpersonating = val
    },

    setUserData(state, data) {
        this.$auth.setUser(data)
    },

    setPosse(state, data) {
        state.posse = data
    },

    setEvents(state, data) {
        state.events = data
    },

    setEvent(state, data) {
        const index = state.events.findIndex((ev) => ev.id === data.id)
        if (index > -1) set(state.events, index, data)
    },

    setPosseEvents(state, data) {
        state.posseEvents = data
    },
}

export const actions = {
    async update({ commit }, payload) {
        return await this.$axios.$put('/api/v1/users/me', payload).then((res) => {
            commit('setUserData', res)
            return res
        })
    },

    async fetch({ commit }) {
        return await this.$axios.$get('/api/v1/users/me').then((res) => {
            commit('setUserData', res)
            return res
        })
    },

    async setTool({ commit }, payload) {
        return await this.$axios.$post(`/api/v1/users/me/setup`, payload).then((res) => {
            commit('setUserData', res)
            return res
        })
    },

    async onboarding({ commit }) {
        return await this.$axios.$post('/api/v1/users/me/onboarding').then((res) => {
            commit('setUserData', res)
            return res
        })
    },

    async styleQuiz({ commit }) {
        return await this.$axios.$post('/api/v1/users/me/stylequiz').then((res) => {
            commit('setUserData', res)
            return res
        })
    },

    async register(vuex, payload) {
        await this.$axios.$post(`/api/v1/auth/register`, payload).then(() => {
            return this.$auth.loginWith('loverly', { data: { username: payload.email, password: payload.password } })
        })
    },

    async finishRegister(vuex, payload) {
        return await this.$axios.$post(`/api/v1/auth/register-finish`, payload).then((res) => {
            return this.$auth.loginWith('loverly', { data: { username: payload.email, password: payload.password } })
        })
    },

    async loginPassword(vuex, payload) {
        // @TODO: Ez itt nagy hack, csak azért hogy validálni tudjuk a juzert login előtt ...
        const result = await this.$axios.$post('/api/v1/auth/login', { email: payload.username, password: payload.password })
        await this.$auth.loginWith('loverly', { data: payload })
        this.$cookies.set('temporary-password', null)
        return result?.data?.user || {}
    },

    async loginSocial({ dispatch }, payload) {
        return await this.$axios.$post(`/api/v1/auth/social/login/${payload.service}`, { token: payload.token }).then((res) => {
            if (res.data) {
                this.$auth.strategy.token.set(`${res.data.token_type}%20${res.data.access_token}`)
                this.$auth.strategy.refreshToken.set(res.data.refresh_token)
                return dispatch('fetch')
            }
        })
    },

    async impersonate({ commit, dispatch }, payload) {
        return await this.$axios.$post(`/api/v1/users/me/${payload.id}/impersonate`).then((res) => {
            if (res.data) {
                this.$cookies.set('impersonate_path', payload.route)
                this.$cookies.set('impersonate_token', this.$auth.strategy.token.get())

                this.$auth.strategy.token.set(res.data.accessToken)

                commit('setImpersonate', true)
                return dispatch('fetch')
            }
        })
    },

    async stopImpersonate({ commit, dispatch }) {
        return await this.$axios.$delete(`/api/v1/users/me/impersonate`).then(() => {
            this.$auth.strategy.token.set(this.$cookies.get('impersonate_token') || undefined)
            this.$cookies.remove('impersonate_token')
            this.$cookies.remove('impersonate_path')
            commit('setImpersonate', false)
            return dispatch('fetch')
        })
    },

    async setPosse({ commit }, id) {
        const response = await this.$axios.$get(`/api/v1/user-posses/get-posse/${id}`)
        if (response.data) {
            commit('setPosse', response.data)
            commit('setPosseEvents', response.data.guest_wedding_events)
        }

        return response
    },

    async getEvents({ commit }) {
        const response = await this.$axios.$get(`/api/v1/guest_wedding_events/all`)
        if (response?.data) {
            commit('setEvents', response?.data)
        }

        return response
    },

    async getEvent({ commit }, id) {
        const response = await this.$axios.$get(`/api/v1/guest_wedding_events/${id}`)

        return response
    },

    async setEvent({ commit }, payload) {
        const response = await this.$axios.$put(`/api/v1/guest_wedding_events/${payload.id}`, payload)
        if (response?.data) {
            commit('setEvent', response?.data)
        }

        return response
    },
}

export const getters = {
    isPremiumUser: (state, getters, rootState) => rootState.auth.loggedIn,
    isPosse: (state) => !!state.posse?.id,
    eventIds: (state) => (state.posseEvents?.length ? state.posseEvents.map((event) => event.id) : []),
    showChecklist: (state) =>
        state.posse?.guest_wedding_events?.length ? state.posse?.guest_wedding_events.some((event) => event.permissions.includes(1)) : !state.posse?.id,
    showGuestList: (state) =>
        state.posse?.guest_wedding_events?.length ? state.posse?.guest_wedding_events.some((event) => event.permissions.includes(2)) : !state.posse?.id,
    showVendorManager: (state) =>
        state.posse?.guest_wedding_events?.length ? state.posse?.guest_wedding_events.some((event) => event.permissions.includes(3)) : !state.posse?.id,
    showMoodBoards: (state) =>
        state.posse?.guest_wedding_events?.length ? state.posse?.guest_wedding_events.some((event) => event.permissions.includes(4)) : !state.posse?.id,
    showTransactions: (state) =>
        state.posse?.guest_wedding_events?.length ? state.posse?.guest_wedding_events.some((event) => event.permissions.includes(5)) : !state.posse?.id,
    checklistEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse?.guest_wedding_events.filter((event) => event.permissions.includes(1)).map((event) => event.id)
            : !state.posse?.id,
    guestListEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse?.guest_wedding_events.filter((event) => event.permissions.includes(2)).map((event) => event.id)
            : !state.posse?.id,
    vendorManagerEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse?.guest_wedding_events.filter((event) => event.permissions.includes(3)).map((event) => event.id)
            : !state.posse?.id,
    moodBoardsEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse?.guest_wedding_events.filter((event) => event.permissions.includes(4)).map((event) => event.id)
            : !state.posse?.id,
    transactionEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse?.guest_wedding_events.filter((event) => event.permissions.includes(5)).map((event) => event.id)
            : !state.posse?.id,
    taxonomyEventIds: (state) =>
        state.posse?.guest_wedding_events?.length
            ? state.posse.guest_wedding_events
                  .filter((event) => event.permissions.includes(1))
                  .map((event) => event.taxonomy_event_id)
                  .filter((id) => id)
            : [],
}
