import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { RefreshScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/sign-in",
    "logout": "/",
    "home": false,
    "callback": "/social-callback"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "sameSite": "lax"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "loverly"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // loverly
  $auth.registerStrategy('loverly', new RefreshScheme($auth, {
  "endpoints": {
    "token": "https://loverly.com/api/v1/auth/token",
    "login": {
      "baseURL": "",
      "url": "/_auth/loverly/token"
    },
    "refresh": {
      "baseURL": "",
      "url": "/_auth/loverly/token"
    },
    "logout": false,
    "user": {
      "url": "https://loverly.com/api/v1/users/me",
      "method": "get"
    },
    "authorization": "https://loverly.com/api/v1/auth/authorize"
  },
  "url": "https://loverly.com",
  "clientId": "2",
  "grantType": "password",
  "name": "loverly",
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "name": "Authorization",
    "maxAge": 31536000
  },
  "refreshToken": {
    "property": "refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": false
  }
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
