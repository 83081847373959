export const state = () => ({
    title: null,
    text: null,
    acceptButtonLabel: null,
    cancelButtonLabel: 'Cancel',
    action: null,
    params: {},
})

export const mutations = {
    setConfirmationData(state, data) {
        state.title = data.title
        state.text = data.text
        state.acceptButtonLabel = data.acceptButtonLabel
        state.cancelButtonLabel = data.cancelButtonLabel || 'Cancel'
        state.action = data.action
        state.params = data.params || {}
    },

    accept() {},

    cancel(state) {
        state.title = null
        state.text = null
        state.acceptButtonLabel = null
        state.cancelButtonLabel = 'Cancel'
        state.action = null
        state.params = {}
    },
}
