
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    computed: {
        megaMenu() {
            return this.model?.megamenu || {}
        },

        promotion() {
            return this.megaMenu?.promotion
        },

        isImageLinkHref() {
            return this.megaMenu?.imageLink?.includes('http')
        },
    },

    mounted() {
        this.$nuxt.$emit('mega-menu-toggle', true)
    },

    destroyed() {
        this.$nuxt.$emit('mega-menu-toggle', false)
    },

    methods: {
        isHref(link) {
            return link && link.includes('http')
        },

        getSubLinks(block) {
            return block?.links?.length ? block.links.filter((el) => el.show_on_mobile) : []
        },
    },
}
