export default {
    watch: {
        model: {
            handler() {
                if (this.model.id)
                    this.$trackEvent('IMPRESSION', {
                        content_type: this.model.content_type.slug,
                        name: this.model.name,
                        id: this.model.id,
                    })
            },
            immediate: true,
        },
    },
}
