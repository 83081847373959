
export default {
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        src: {
            type: String,
            default: undefined,
        },
        alt: {
            type: String,
            default: '',
        },

        size: {
            type: [Number, String],
            default: 32,
        },
        border: Boolean,
        borderDark: Boolean,
    },

    computed: {
        avatarSrc() {
            if (this.user?.avatar?.url) return this.user.avatar.url
            if (this.user?.cover?.url) return this.user.cover.url
            if (this.src) return this.src
            let name = [this.user?.firstname, this.user?.lastname].filter(Boolean).join(' ')
            if (!name || name === ' ') name = this.user?.name
            if (name && name !== ' ') return `https://ui-avatars.com/api/?name=${name}&size=256&background=random`
            return '/newuser.png'
        },
        imageAlt() {
            return this.user?.name || this.alt || 'User avatar'
        },
    },
}
