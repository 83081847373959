
import { mapState } from 'vuex'
import openable from '~/mixins/openable'

export default {
    mixins: [openable],

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'masterclass.json')
    },

    data() {
        return {
            scrolled: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            nextLesson: (state) => state.data.nextLesson,
            lessonNav: (state) => state.data.lessonNav,
            content: (state) => state.jsonloader.data['masterclass.json'] || null,
        }),
    },

    mounted() {
        window.addEventListener('scroll', this.handleOffsetTopChange)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleOffsetTopChange)
    },

    methods: {
        handleOffsetTopChange() {
            this.scrolled = document.documentElement.scrollTop > 0
        },

        isMasterclassRoute() {
            return this.$route.name.includes('users-username-courses')
        },

        isCourseRoute() {
            return this.$route.name.includes('users-username-courses-slug')
        },

        isLessonRoute() {
            return this.$route.name.includes('users-username-courses-slug-lesson')
        },

        exit() {
            this.$router.push({ name: 'dashboard' })
        },

        getLink(lesson) {
            const urlArray = lesson.url.split('/')
            return { name: 'users-username-courses-slug-lesson', params: { username: this.user.username, slug: urlArray[2], lesson: urlArray[3] } }
        },

        setLessonCompleted() {
            try {
                if (this.$gtag && this.$gtag.event)
                    this.$gtag.event('course-complete', { method: 'Google', event_category: 'course', event_label: this.content?.name, value: 0 })
            } catch (e) {}
            this.$axios.$post(`/api/v1/users/me/lesson/${this.$route.params.lesson}`).then((res) => {
                this.$store.dispatch('user/update', res.data)
                let jumpTo
                if (this.lessonNav.next) {
                    jumpTo = this.getLink(this.lessonNav.next)
                } else {
                    jumpTo = { name: 'users-username-courses-slug', params: { username: this.user.username, slug: this.$route.params.slug } }
                }
                this.$router.push(jumpTo)
            })
        },
    },
}
