
import { mapMutations } from 'vuex'

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        closeable: {
            type: Boolean,
            default: false,
        },

        autoClose: {
            type: Boolean,
            default: true,
        },

        title: {
            type: String,
            default: '',
        },
    },

    watch: {
        visible(val) {
            if (val) {
                document.querySelector('body').classList.add('modal-open')
            } else {
                document.querySelector('body').classList.remove('modal-open')
                this.setConfirmationData({})
            }
        },
    },

    mounted() {
        if (!document.querySelector('#modal-bg')) {
            const el = document.createElement('div')
            el.classList.add('modal-bg')
            el.id = 'modal-bg'
            document.querySelector('body').appendChild(el)
        }
    },

    methods: {
        ...mapMutations({
            setConfirmationData: 'confirmation/setConfirmationData',
        }),
    },
}
