
export default {
    inheritAttrs: false,

    props: {
        src: {
            type: null,
            default: '',
        },

        alt: {
            type: String,
            default: 'Image',
        },

        layout: {
            type: String,
            default: 'intrinsic',
        },

        svg: Boolean,

        align: {
            type: String,
            default: 'center',
        },

        classes: {
            type: Array,
            default: () => ['max-w-full'],
        },

        fullHeight: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            noImage: null,
        }
    },

    methods: {
        load() {
            this.noImage = false
            this.$emit('loaded', this.noImage)

            if (this.$slots.default) this.setWidth()
        },

        error() {
            this.noImage = true
            this.$emit('noImage', this.noImage)
        },

        setWidth() {
            const image = document.querySelector('#image-' + this._uid)
            const figCaption = document.querySelector('#figcaption-' + this._uid)
            if (image && figCaption) {
                const imageWidth = image.clientWidth
                figCaption.style.maxWidth = imageWidth + 'px'
            }
        },
    },
}
