export default function ({ $axios, $auth, $toast, redirect, store }) {
    $axios.onError((error) => {
        if (error && error.response) {
            if (error.response.status === 401) {
                if ($auth) $auth.logout()
                redirect({ name: 'index' })
            }
            if (error.response.status === 403) {
                $toast.error('Access forbidden!', {}).goAway(3000)
            }
            if (error.response.status === 422) {
                let msg = 'Validation error!'
                if (error.response && error.response.data) {
                    const messages = []
                    msg = error.response.data.message
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach((el) => {
                            const arr = error.response.data.errors[el]
                            messages.push(arr)
                        })
                    }
                    msg += ' ' + messages.join(' ')
                }
                $toast.error(msg, {}).goAway(3000)
            }
            if (error.response.status === 429) {
                $toast.error('Too many requests!', {}).goAway(3000)
            }
            if (error.response.status === 500) {
                $toast.error('Something went wrong', {}).goAway(3000)
            }
            if (error.response.status === 503) {
                $toast.info('Deployment in progress ..', {}).goAway(3000)
            }
        } else {
            // eslint-disable-next-line no-console
            console.warn('Request general error: ', error)
        }
    })
}
