
import { mapState } from 'vuex'
import toMoney from '@/mixins/toMoney'

export default {
    mixins: [toMoney],

    props: {
        small: Boolean,
        value: {
            type: null,
            default: null,
        },
    },

    computed: {
        ...mapState({
            products: (state) => state.products.list || [],
            product: (state) => state.products.item || [],
        }),

        productGroup() {
            return this.product?.details?.metadata?.product_group || null
        },

        paymentPeriods() {
            if (!this.productGroup) return []
            return this.products.filter((el) => {
                return el.details?.metadata?.product_group === this.productGroup
            })
        },
    },

    methods: {
        changeProduct(product) {
            this.$store.dispatch('products/setItem', product)
        },

        getAdditionalInfo(paymentPeriod) {
            return paymentPeriod.details?.metadata?.additional_info
        },

        getPeriodText(paymentPeriod) {
            return paymentPeriod.details?.metadata?.text
        },
    },
}
