import { mapState } from 'vuex'
import toMoney from '@/mixins/toMoney'
import productName from '@/components/pages/payment/mixins/productName'

export default {
    mixins: [productName, toMoney],

    props: {
        product: {
            type: String,
            default: '',
        },
    },

    async fetch() {
        try {
            this.$store.dispatch('jsonloader/fetchJson', 'payment.json')
            if (this.customer?.id) return
            await this.$store.dispatch('products/fetchCustomer')
        } catch (error) {
            this.$toast.error(error).goAway(10000)
        }
    },

    computed: {
        ...mapState({
            productObject: (state) => state.products.item || {},
            content: (state) => state.jsonloader.data['payment.json'] || null,
        }),

        link() {
            return this.$route.query.redirect_url || null
        },

        successButtonLabel() {
            return this.content?.pay_button_label.replace('%amount%', `$${this.total}`)
        },

        unitAmount() {
            return this.productObject?.unit_amount ? this.productObject.unit_amount / 100 : 0
        },

        total() {
            if (this.coupon?.id) {
                const price = this.unitAmount
                if (this.coupon.amount_off) {
                    const computedPrice = price - this.coupon.amount_off / 100
                    return computedPrice > 0 ? this.toMoney(computedPrice, true) : 0
                } else return this.toMoney(price - (price * this.coupon.percent_off) / 100, true)
            }
            return this.toMoney(this.unitAmount, true)
        },

        discount() {
            if (this.coupon?.id) {
                const price = this.unitAmount
                if (this.coupon.amount_off) return this.toMoney(this.coupon.amount_off / 100, true)
                else return this.toMoney((price * this.coupon.percent_off) / 100, true)
            }
            return 0
        },
    },

    watch: {
        product: {
            handler() {
                if (this.product) this.$store.dispatch('products/setItem', this.product)
            },
            immediate: true,
        },
    },
}
