import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            posseEvents: (state) => state.user.posseEvents || [],
            user: (state) => state.auth.user,
        }),

        users() {
            return this.user?.users?.length ? this.user.users.filter((user) => user.is_accepted).map((el) => el.user) : []
        },
    },

    methods: {
        setPosse(user) {
            this.$store.dispatch('user/setPosse', user.id).then(() => {
                if (this.posseEvents?.length)
                    this.$router.push({ name: 'dashboard-posse-user-events-id', params: { user: user.id, id: this.posseEvents[0].id } })
            })
        },

        isCurrentPosse(id) {
            return this.$route.name === 'dashboard-posse-user-events-id' && parseInt(this.$route.params.user, 0) === id
        },
    },
}
