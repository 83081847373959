
import { mapState } from 'vuex'

export default {
    props: {
        value: {
            type: null,
            default: null,
        },
    },

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'payment.json')
    },

    data() {
        return {
            show: false,
            coupon: null,
            error: false,
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['payment.json'] || null,
        }),

        couponLabel() {
            return this.coupon ? this.content.add_another_coupon_code : this.content.have_coupon_code
        },
    },

    watch: {
        value() {
            this.coupon = this.value
        },

        coupon() {
            this.error = false
        },
    },

    methods: {
        validateCoupon() {
            this.$toast.info(this.content.validating_coupon).goAway(3000)
            this.$axios
                .$post(`/api/v1/payments/coupon`, { coupon: this.coupon })
                .then((res) => {
                    this.error = false
                    this.$emit('input', res)
                    this.$toast.success(this.content.added_coupon).goAway(3000)
                    this.show = false
                })
                .catch(() => {
                    this.error = true
                    this.$toast.error(this.content.coupon_error).goAway(3000)
                })
        },
    },
}
