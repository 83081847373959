
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            isLoading: false,
        }
    },

    computed: {
        ...mapState({
            title: (state) => state.confirmation.title,
            text: (state) => state.confirmation.text,
            acceptButtonLabel: (state) => state.confirmation.acceptButtonLabel,
            cancelButtonLabel: (state) => state.confirmation.cancelButtonLabel,
            action: (state) => state.confirmation.action,
            params: (state) => state.confirmation.params,
        }),

        isOpen() {
            return !!(this.title && this.acceptButtonLabel)
        },
    },

    watch: {
        isOpen() {
            if (!this.isOpen) this.isLoading = false
        },
    },

    methods: {
        ...mapMutations({
            accept: 'confirmation/accept',
            cancel: 'confirmation/cancel',
        }),
    },
}
