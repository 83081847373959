
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    computed: {
        isHref() {
            return this.model?.link && this.model.link.includes('http')
        },
    },
}
