export const actions = {
    async nuxtServerInit({ dispatch, commit }) {
        try {
            await dispatch('data/fetch')
            dispatch('jsonloader/fetch')
            try {
                commit('user/setImpersonate', this.$cookies.get('impersonate_token') !== undefined)
            } catch (e) {}
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Site Init Failed! Error: ', e, e?.response?.data)
        }
    },
}
