export const state = () => ({
    list: [],
    item: {},
    methods: [],
    customer: {},
    modal: false,
})

export const mutations = {
    setItems(state, data) {
        state.list = data
    },

    setItem(state, data) {
        if (!data) {
            state.item = {}
            return
        }
        if (!state.list || !state.list.length) return

        if (data?.id) {
            state.item = data
        } else {
            state.item = state.list.find((el) => {
                return el.product === data
            })
        }
    },

    setPaymentMethods(state, data) {
        state.methods = data
    },

    setCustomer(state, data) {
        state.customer = data
    },

    triggerProductModal(state, data) {},
}

export const actions = {
    async fetchItems({ commit }) {
        const res = await this.$axios.$get('/api/v1/payments/products')
        commit('setItems', res.data)
    },

    async setItem({ commit, dispatch, state }, payload) {
        if (state.list?.length) {
            await commit('setItem', payload)
        } else {
            await dispatch('fetchItems').then(() => {
                commit('setItem', payload)
            })
        }
    },

    async fetchPaymentMethods({ commit }) {
        const res = await this.$axios.$get('/api/v1/payments/payment-methods')
        commit('setPaymentMethods', res.data)
    },

    async fetchCustomer({ commit }) {
        const res = await this.$axios.$get(`/api/v1/payments/customer`)
        commit('setCustomer', res.data)
    },
}
