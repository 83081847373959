import { mapState } from 'vuex'
import thousandsFilter from '@/mixins/thousandsFilter'
export default {
    mixins: [thousandsFilter],

    computed: {
        ...mapState({
            registry: (state) => state.data.registry || {},
            users: (state) => state.data.usersCount,
        }),

        formattedUsers() {
            return this.$options.filters.thousands(this.users || '')
        },
    },

    methods: {
        getRegistry(key) {
            return this.registry && this.registry[key] ? this.registry[key] : ''
        },
    },
}
