
export default {
    data() {
        return {
            links: {
                instagram: 'https://www.instagram.com/loverly',
                facebook: 'https://www.facebook.com/loverly/',
                pinterest: 'https://pinterest.com/loverly/',
                twitter: 'https://twitter.com/loverly',
                youtube: 'https://www.youtube.com/channel/UCRmxkvBuz7yQ0jVpl1WlaAw',
            },
        }
    },

    methods: {
        handleClick(url, name) {
            this.$trackEvent('OUTBOUND_CLICK', {
                target_url: url,
                name,
                content_type: 'social',
            })
        },
    },
}
