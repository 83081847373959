
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        footerConfig() {
            return this.globalTexts?.footer || {}
        },

        footerColumns() {
            return this.footerConfig?.columns || []
        },
    },
}
