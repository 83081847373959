
export default {
    props: {
        login: {
            type: Boolean,
        },
        isVendor: Boolean,
    },

    data() {
        return {
            isLoginForm: this.login,
        }
    },

    methods: {
        switchModal(isVendor = false) {
            this.isVendor = isVendor
            this.isLoginForm = !this.isLoginForm
        },
    },
}
