
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    props: {
        userInfos: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
            tasks_count: (state) => state.data.tasks_count,
            isMenuOpen: (state) => state.data.isMenuOpen,
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        menu() {
            try {
                const menu = JSON.parse(JSON.stringify(this.globalTexts.dashboardMenu))
                return menu
                    .map((el) => {
                        if (el.to) el.to = el.to.replace('%username%', this.user.username)
                        if (el.key === 'masterclass') {
                            el.to = this.isPremiumUser ? { name: 'users-username-courses', params: { username: this.user.username } } : { name: 'i-do-crew' }
                            el.condition = this.$config.masterclassEnabled
                        }
                        if (el.to === '/tools/vision-boards' || el.to === '/tools/vision-boards/setup') {
                            if (this.user.saved_ideas_setup_at) el.to = '/style-quiz/result'
                            else el.to = '/tools/vision-boards/setup'
                        }

                        return el
                    })
                    .filter((el) => !el.is_hidden)
            } catch (e) {}
            return []
        },

        isExpanded() {
            return this.$route.name.includes('dashboard')
        },
    },

    watch: {
        $route: {
            handler() {
                if (process.browser) {
                    this.$nextTick(() => {
                        if (window.innerWidth > 992 && !this.isMenuOpen) this.toggleMenuOpen()
                        if (window.innerWidth < 992 && this.isMenuOpen) this.toggleMenuOpen()
                    })
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapMutations({
            toggleMenuOpen: 'data/toggleMenuOpen',
        }),

        isActive(item) {
            return this.$route.name.includes(item.to.name)
        },

        logout() {
            this.$auth.logout()
            this.$trackEvent('LOGOUT')
            this.$trackConnect()
        },
    },
}
