
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        copyRight() {
            return this.globalTextFooter.copyright_1?.replace('{{year}}', new Date().getFullYear())
        },

        globalTextFooter() {
            return this.globalTexts?.footer || {}
        },
    },
}
