export const state = () => ({
    course: {},
    lesson: [],
})

export const mutations = {
    setCourse(state, data) {
        state.course = data
    },

    setLesson(state, data) {
        state.lesson = data
    },
}

export const actions = {
    setCourse({ commit }, payload) {
        return new Promise((resolve) => {
            commit('setCourse', payload)
            resolve()
        })
    },

    setLesson({ commit }, payload) {
        return new Promise((resolve) => {
            commit('setLesson', payload)
            resolve()
        })
    },
}
