import Vue from 'vue'

export const state = () => ({
    data: {},
    loadedJsons: [],
    defaultGlobalJson: 'global.json',
})

export const mutations = {
    setJsonData(state, data) {
        Vue.set(state.data, data.key, data.value)
    },

    setloadedJsons(state, data) {
        state.loadedJsons.push(data)
    },
}

export const actions = {
    fetch({ dispatch, state }) {
        dispatch('fetchJson', state.defaultGlobalJson)
    },

    async flushCache({ dispatch }) {
        await dispatch('fetch')
    },

    async fetchJson({ commit, state, rootState }, payload) {
        return await new Promise((resolve) => {
            try {
                const jsons = rootState?.data?.jsons || {}
                if (!state.loadedJsons.includes(payload)) {
                    commit('setloadedJsons', payload)
                    if (jsons[payload]) {
                        commit('setJsonData', { key: payload, value: jsons[payload] })
                        resolve(jsons[payload])
                    } else {
                        try {
                            this.$axios.get(`/api/v1/static/json-files/${payload}`).then((res) => {
                                commit('setJsonData', { key: payload, value: res.data })
                                resolve(res.data)
                            })
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log('Failed fetching json: ', e, e?.response?.data)
                            resolve({})
                        }
                    }
                }
                resolve(state.loadedJsons[payload])
            } catch (e) {
                resolve({})
            }
        })
    },
}
