export const state = () => ({
    gapiInited: false,
    gisInited: false,
    isGoogleCalendarInited: false,
})

export const mutations = {
    setIsGoogleCalendarInited(state, data) {
        state.isGoogleCalendarInited = data
    },

    setGapiInited(state, data) {
        state.gapiInited = data
    },

    setGisInited(state, data) {
        state.gisInited = data
    },
}
