
import { mapState } from 'vuex'

export default {
    data() {
        return {
            megaMenuItem: null,
        }
    },

    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        megaMenu() {
            return this.globalTexts?.megaMenu || {}
        },
    },

    methods: {
        setMegaMenuItem(val) {
            this.megaMenuItem = val && this.megaMenuItem && val.slug === this.megaMenuItem.slug ? null : val
            this.$emit('open', !!this.megaMenu)
        },

        closeMegaMenuItem(onlyDesktop = false) {
            if (process.browser && onlyDesktop && window.innerWidth < 992) return
            this.megaMenuItem = null
            this.$emit('open', false)
        },
    },
}
