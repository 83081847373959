
import hasModel from '@/mixins/hasModel'
export default {
    mixins: [hasModel],

    props: {
        isOpen: Boolean,
    },

    data() {
        return {
            openTimeOut: null,
        }
    },

    methods: {
        setMegaMenuItem() {
            this.$emit('openMegaMenuItem', this.model)
        },

        clickHandler() {
            if (!this.isOpen && process.browser && window.innerWidth < 992) this.setMegaMenuItem()
        },

        hoverHandler() {
            if (!this.openTimeOut)
                this.openTimeOut = setTimeout(() => {
                    if (!this.isOpen && process.browser && window.innerWidth > 992) this.setMegaMenuItem()
                }, 300)
        },

        cancelOpening() {
            if (!this.isOpen) {
                clearTimeout(this.openTimeOut)
                this.openTimeOut = null
            }
        },
    },
}
