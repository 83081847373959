import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),
        isVendor() {
            return !!this.user?.is_vendor
        },
        onVendorDashboard() {
            return this.$route.name != 'tools-vendor-manager' && this.$route.name.includes('vendor-')
        },
        masterclassEnabled() {
            return this.$config.masterclassEnabled
        },

        menu() {
            return this.isVendor && this.onVendorDashboard
                ? [
                      { name: 'Dashboard', key: 'dashboard', icon: 'sliders', to: { name: 'vendor-dashboard' } },
                      { name: 'My Tasks', key: 'tasks', icon: 'check-circle', to: { name: 'vendor-checklist' } },
                      { name: 'Profile', key: 'profile', icon: 'users', to: { name: 'vendor-management-step', params: { step: 1 } } },
                      { name: 'Submissions', key: 'submissions', icon: 'paper-plane', to: { name: 'tools-vendor-submit-wedding-my-weddings' } },
                  ]
                : [
                      ...[
                          { name: 'Home', key: 'dashboard', icon: 'sliders', to: { name: 'dashboard' } },
                          { name: 'Checklist', key: 'checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } },
                          { name: 'Guests', key: 'guest_list', icon: 'users', to: { name: 'tools-guest-list' } },
                      ],

                      // ...(this.masterclassEnabled ? [{ name: 'Videos & Guides', key: 'videos_guides', icon: 'video', to: { name: 'tools-videos' } }] : []),
                      ...[
                          { name: 'Vendors', key: 'vendor_manager', icon: 'briefcase', to: { name: 'tools-vendor-manager' } },
                          // { name: 'My Submissions', key: 'submissions', icon: 'rings-wedding', to: { name: 'tools-submit-wedding-my-weddings' } },
                          {
                              name: 'Boards',
                              key: 'mood_boards',
                              icon: 'heart',
                              to: { name: 'users-username-boards', params: { username: this.user?.username } },
                          },
                      ],
                      ...(this.$config.birdieEnabled && this.user?.wedding_details?.date
                          ? [
                                {
                                    name: 'Registry',
                                    icon: 'gift',
                                    to: { name: 'tools-cash-registry' },
                                },
                            ]
                          : []),
                  ]
        },
    },
}
