export default {
    methods: {
        toMoney(val, withCurrency = false, defaultValue = 0) {
            return val
                ? `${withCurrency ? '$' : ''}${parseFloat(val)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                : defaultValue
        },
    },
}
